import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "my-4 px-10 py-1" }
const _hoisted_2 = { class: "flex space-x-4 mb-3" }
const _hoisted_3 = { class: "font-bold" }
const _hoisted_4 = { class: "box 2xl:py-5 py-3" }
const _hoisted_5 = { class: "w-full" }
const _hoisted_6 = { class: "absolute bottom-10 right-8 space-y-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("ol", _hoisted_2, [
      _createElementVNode("li", null, [
        _createVNode(_component_router_link, { to: "/setting-information" }, {
          default: _withCtx(() => _cache[2] || (_cache[2] = [
            _createTextVNode("Seting Information")
          ])),
          _: 1
        })
      ]),
      _cache[4] || (_cache[4] = _createElementVNode("li", null, ">", -1)),
      _createElementVNode("li", _hoisted_3, [
        _createVNode(_component_router_link, { to: "/setting-information/scope-1-and-scope-3-emissions" }, {
          default: _withCtx(() => _cache[3] || (_cache[3] = [
            _createTextVNode(" Scope 1, 2, 3 Emissions ")
          ])),
          _: 1
        })
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode($setup["Scope1And3Filter"], {
        class: "my-4",
        countries: $setup.dataCountries,
        dataFacilities: $setup.dataFacilities.data,
        onHandleChangeCountry: $setup.handleChangeCountry,
        hiddenYear: true
      }, null, 8, ["countries", "dataFacilities"]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", {
          class: _normalizeClass(['my-5 relative', _ctx.styles['container__chart']])
        }, [
          (!$setup.isLoadingGetEmissionHistory)
            ? (_openBlock(), _createBlock($setup["ColumnChartStack"], {
                key: 0,
                class: _normalizeClass(_ctx.styles['column-size']),
                data: $setup.dataScopeThreeHistory,
                colors: ['#2b74a8', '#2b74a8', '#5e5e5e'],
                data_2: $setup.dataScopeTwoHistory,
                colors_2: ['#ffeecc', '#ffeecc', '#5e5e5e'],
                data_3: $setup.dataScopeOneHistory,
                colors_3: ['#1ccca8', '#1ccca8', '#5e5e5e'],
                lineMarkerName: `Short term target`,
                lineMarker: $setup.GHGTargetsShortTermChart,
                lineData: $setup.GHGTargetsLongTermChart,
                maxValue: $setup.dataTotalScopeHistory.valueLeftColumnChart,
                baseYear: $setup.GHGTargets.BaseYear,
                isBaseYear: true,
                lineSeriesName: "Long term target",
                seriesName: "Scope 3",
                seriesName_2: "Scope 2",
                seriesName_3: "Scope 1",
                yTitle: "Tonnes CO2",
                isColorBaseLine: true,
                isChartXScroll: true,
                legendStyle: {
              position: 'right',
              fontSize: 14,
              padding: [0, 50, 50, 50],
            },
                legendLayout: "vertical"
              }, null, 8, ["class", "data", "data_2", "data_3", "lineMarker", "lineData", "maxValue", "baseYear"]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_6, [
            _createVNode(_component_router_link, {
              to: `/${$setup.SETTING_INFORMATION.path}/${$setup.SETTING_INFORMATION.SETTING_BASELINE.path}`,
              class: "text-blue-500 underline"
            }, {
              default: _withCtx(() => _cache[5] || (_cache[5] = [
                _createTextVNode(" Baseline Target ")
              ])),
              _: 1
            }, 8, ["to"]),
            _createElementVNode("button", {
              class: "btn border-2 border-white hover:bg-gray-700 active:bg-gray-800 w-48",
              onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (
                $setup.router.push(
                  `scope-1-and-scope-3-emissions/input-data-manual${$setup.route.hash}`
                )
              ), ["prevent"]))
            }, " Input data manually "),
            _createElementVNode("button", {
              class: "btn btn--red-primary w-48",
              onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (
                $setup.router.push('/setting-information/your-green-options')
              ), ["prevent"]))
            }, " Your Green Options ")
          ])
        ], 2)
      ])
    ])
  ]))
}