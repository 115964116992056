import { defineComponent as _defineComponent } from 'vue'
import { Field, Form } from "vee-validate";
import { computed, toRefs } from "vue";
import { useRouter } from "vue-router";
import ScopeOneAndThreeEmissionModule from "@ems/containers/ScopeOneAndThreeEmission/ScopeOneAndThreeEmission.module";
import MetadataModule from "@/store/modules/Metadata";
interface Props {
  countries: any[];
  dataFacilities: any[];
  hiddenYear?: boolean;
}

export default /*@__PURE__*/_defineComponent({
  __name: 'Scope1And3.Filter',
  props: {
    countries: {},
    dataFacilities: {},
    hiddenYear: { type: Boolean }
  },
  emits: ["handleChangeCountry"],
  setup(__props: any, { expose: __expose, emit: __emit }) {
  __expose();

const emits = __emit;

const dataCurrentYearScopeTwoEmission = computed<string | number | Date>(
  () => ScopeOneAndThreeEmissionModule.dataCurrentYearScopeOneAndThreeGetter
);
const initialValues = {
  Year: dataCurrentYearScopeTwoEmission.value.toString(),
  Country: null,
  FacilityId: null,
};
const props = __props;
const { countries, dataFacilities, hiddenYear } = toRefs(props);

const router = useRouter();

const handleChangeDropdownCountry = (values: any, setFieldValue: any) => {
  MetadataModule.setCurrentCountries(values);
  setFieldValue("FacilityId", null);
  emits("handleChangeCountry", values);
};
const onSubmit = async (values: any) => {
  router.push("/");
};

const __returned__ = { emits, dataCurrentYearScopeTwoEmission, initialValues, props, countries, dataFacilities, hiddenYear, router, handleChangeDropdownCountry, onSubmit, get Field() { return Field }, get Form() { return Form }, computed, toRefs, get useRouter() { return useRouter }, get ScopeOneAndThreeEmissionModule() { return ScopeOneAndThreeEmissionModule }, get MetadataModule() { return MetadataModule } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})