import { defineComponent as _defineComponent } from 'vue'
import ColumnChartStack from "@/components/AnyChart/ColumnChartStack.vue";
import MetadataModule from "@/store/modules/Metadata";
import { SETTING_INFORMATION } from "@ems/constants";
import EmissionModule from "@ems/containers/Emission/Emission.module";
import Scope1And3Filter from "@ems/containers/GreenOptions/TheForm/Scope1And3.Filter.vue";
import ScopeOneAndThreeEmissionModule from "@ems/containers/ScopeOneAndThreeEmission/ScopeOneAndThreeEmission.module";
import ScopeTwoEmissionModule from "@ems/containers/ScopeTwoEmission/ScopeTwoEmission.module";
import SettingBaseLineModule from "@ems/containers/SettingInformation/SettingBaseLine.module";
import { computed, onMounted, reactive, watch } from "vue";
import { useRoute, useRouter } from "vue-router";


export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  setup(__props, { expose: __expose }) {
  __expose();

const router = useRouter();
const route = useRoute();

const setDefaultFacilitiesData = (data: any) => {
  data.unshift({
    CountryId: "",
    CountryName: "",
    CountryCode: "",
    Red: false,
    Url: "",
    Latitude: 0,
    Longitude: 0,
    Value: null,
    Name: "All",
  });
};
const currentYear = computed<string | number | Date>(
  () => ScopeOneAndThreeEmissionModule.dataCurrentYearScopeOneAndThreeGetter
);

const dataCurrentYearScopeOneAndThree = computed<string | number | Date>(
  () => ScopeOneAndThreeEmissionModule.dataCurrentYearScopeOneAndThreeGetter
);
const dataCurrentFacilityScopeOneAndThree = computed(
  () => ScopeOneAndThreeEmissionModule.dataCurrentFacilityScopeOneAndThreeGetter
);

const isLoadingGetEmissionHistory = computed(
  () => EmissionModule.isLoadingGetEmissionHistoryGetter
);
const GHGTargets = computed(() => SettingBaseLineModule.GHGTargetsGetter);
const GHGTargetsShortTermChart = computed(
  () => SettingBaseLineModule.GHGTargetsShortTermChartGetter
);
const GHGTargetsLongTermChart = computed(
  () => SettingBaseLineModule.GHGTargetsLongTermChartGetter
);

const dataScopeThreeHistory = computed(
  () => EmissionModule.ScopeThreeHistoryGetter
);

const dataScopeTwoHistory = computed(
  () => EmissionModule.ScopeTwoHistoryGetter
);

const dataScopeOneHistory = computed(
  () => EmissionModule.ScopeOneHistoryGetter
);
const dataTotalScopeHistory = computed(
  () => EmissionModule.TotalScopeHistoryGetter
);

watch(
  () => [
    dataCurrentFacilityScopeOneAndThree.value,
    dataCurrentYearScopeOneAndThree.value,
  ],
  () => {
    EmissionModule.getEmissionHistory({
      FacilityId: dataCurrentFacilityScopeOneAndThree.value,
    });
  }
);
onMounted(() => {
  setDefaultFacilitiesData(dataFacilities.data);

  EmissionModule.getEmissionHistory({});
  SettingBaseLineModule.getGHGTargetsAction();
});

const dataFacilities = reactive<any>({ data: [] });

const dataCountries = computed(
  () => MetadataModule.dataInputManualCountriesGetter
);

const handleChangeCountry = async (values: any) => {
  const facilitiesGetter = MetadataModule.dataFacilitiesGetter;

  dataFacilities.data = facilitiesGetter.filter(
    (fac) => fac.CountryId === values
  );
  setDefaultFacilitiesData(dataFacilities.data);
  return dataFacilities.data;
};

const __returned__ = { router, route, setDefaultFacilitiesData, currentYear, dataCurrentYearScopeOneAndThree, dataCurrentFacilityScopeOneAndThree, isLoadingGetEmissionHistory, GHGTargets, GHGTargetsShortTermChart, GHGTargetsLongTermChart, dataScopeThreeHistory, dataScopeTwoHistory, dataScopeOneHistory, dataTotalScopeHistory, dataFacilities, dataCountries, handleChangeCountry, ColumnChartStack, get MetadataModule() { return MetadataModule }, get SETTING_INFORMATION() { return SETTING_INFORMATION }, get EmissionModule() { return EmissionModule }, Scope1And3Filter, get ScopeOneAndThreeEmissionModule() { return ScopeOneAndThreeEmissionModule }, get ScopeTwoEmissionModule() { return ScopeTwoEmissionModule }, get SettingBaseLineModule() { return SettingBaseLineModule }, computed, onMounted, reactive, watch, get useRoute() { return useRoute }, get useRouter() { return useRouter } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})