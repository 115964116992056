import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, mergeProps as _mergeProps, withCtx as _withCtx, createVNode as _createVNode, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_date_picker = _resolveComponent("el-date-picker")!

  return (_openBlock(), _createBlock($setup["Form"], {
    as: "el-form",
    onSubmit: $setup.onSubmit,
    class: "flex flex-wrap",
    "label-position": "top",
    "initial-values": $setup.initialValues
  }, {
    default: _withCtx(({ setFieldValue }) => [
      _createVNode($setup["Field"], { name: "Country" }, {
        default: _withCtx(({ value, field, errorMessage }) => [
          _createVNode(_component_el_form_item, {
            class: "inputfield inputfield__underline mx-5",
            error: errorMessage,
            label: "Country"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_select, _mergeProps({ class: "w-56" }, field, {
                "model-value": value,
                placeholder: "",
                onChange: (values) =>$setup.handleChangeDropdownCountry(values, setFieldValue)
              }), {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.countries, (item) => {
                    return (_openBlock(), _createBlock(_component_el_option, {
                      key: item.Value,
                      label: item.Name,
                      value: item.Value
                    }, null, 8, ["label", "value"]))
                  }), 128))
                ]),
                _: 2
              }, 1040, ["model-value", "onChange"])
            ]),
            _: 2
          }, 1032, ["error"])
        ]),
        _: 2
      }, 1024),
      _createVNode($setup["Field"], { name: "FacilityId" }, {
        default: _withCtx(({ value, field, errorMessage }) => [
          _createVNode(_component_el_form_item, {
            class: "inputfield inputfield__underline mx-5",
            error: errorMessage,
            label: "Facility"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_select, _mergeProps({ class: "w-56" }, field, {
                "model-value": value,
                placeholder: "",
                onChange: _cache[0] || (_cache[0] = (values) => $setup.ScopeOneAndThreeEmissionModule.currentFacilityScopeOneAndThreeEmission(values))
              }), {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.dataFacilities, (item) => {
                    return (_openBlock(), _createBlock(_component_el_option, {
                      key: item.Value,
                      label: item.Name,
                      value: item.Value
                    }, null, 8, ["label", "value"]))
                  }), 128))
                ]),
                _: 2
              }, 1040, ["model-value"])
            ]),
            _: 2
          }, 1032, ["error"])
        ]),
        _: 1
      }),
      (!$setup.hiddenYear)
        ? (_openBlock(), _createBlock($setup["Field"], {
            key: 0,
            name: "Year"
          }, {
            default: _withCtx(({ value, field, errorMessage }) => [
              _createVNode(_component_el_form_item, {
                class: "inputfield inputfield__underline mx-5",
                error: errorMessage,
                label: "Year"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_date_picker, _mergeProps(field, {
                    "model-value": value,
                    type: "year",
                    editable: false,
                    "disabled-date": (time) => time.getTime() > Date.now(),
                    placeholder: "",
                    onChange: ($event: any) => ($setup.ScopeOneAndThreeEmissionModule.currentYearScopeOneAndThreeEmission(value as string | number | Date))
                  }), null, 16, ["model-value", "disabled-date", "onChange"])
                ]),
                _: 2
              }, 1032, ["error"])
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}